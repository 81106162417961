import React from 'react'

import styled, { css, ThemeContext } from 'styled-components'
import SEO from '../components/seo'
import * as System from '../../design-system'

const ConstructionModeWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Page = () => (
  <div>
    <SEO title="Construction Mode On" />
    <ConstructionModeWrap>
      <System.Text text="👷‍♀️ Construction Mode On 👷‍♂️" />
    </ConstructionModeWrap>
  </div>
)

export default Page
